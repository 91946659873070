import React from 'react';
import './App.css';
import api from './api';
// import logo from './images/logo.svg';

function App() {
  // const [users, setUsers] = React.useState(0);
  const [posts, setPosts] = React.useState(0);

  React.useEffect(() => {
    const getCounts = async () => {
      // const number = await api.getUserCount();
      const posts = await api.getPostCount();

      console.log('posts', posts.toString())
      // setUsers(number);
      setPosts(posts)
      // setTimeout(getCounts, 3000)
    };
    getCounts()
  }, [])

  return (
    <span>{`${(Number(posts.toString())/1000).toFixed(1)} k`}</span>
  );

  // return (
  //   <div
  //     style={{
  //       height: "100vh",
  //       display: "flex",
  //       flexDirection: "column",
  //       alignItems: "center",
  //     }}
  //   >
  //     <div
  //       style={{
  //         padding: "50px 16px",
  //         alignItems: "center",
  //         display: "flex",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <img src={logo} alt="Logo" width="200" />
  //     </div>
  //     <div
  //       style={{
  //         flex: 1,
  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <div
  //         style={{
  //           justifyContent: "center",
  //           display: "flex",
  //         }}
  //       >
  //         <h1 style={{ fontSize: 22, textTransform: "uppercase" }}>User count</h1>
  //       </div>
  //       <div
  //         style={{
  //           fontSize: 14,
  //           color: "#444e9f",
  //           textDecoration: "none",
  //         }}
  //       >
  //         <h1
  //           style={{
  //             fontSize: 80,
  //             color: "#ff703d",
  //             textTransform: "uppercase",
  //           }}
  //         >
  //           {users.toString()}
  //         </h1>
  //       </div>
  //       <div
  //         style={{
  //           justifyContent: "center",
  //           display: "flex",
  //         }}
  //       >
  //         <h1 style={{ fontSize: 22, textTransform: "uppercase" }}>Post count</h1>
  //       </div>
  //       <div
  //         style={{
  //           fontSize: 14,
  //           color: "#444e9f",
  //           textDecoration: "none",
  //         }}
  //       >
  //         <h1
  //           style={{
  //             fontSize: 80,
  //             color: "#ff703d",
  //             textTransform: "uppercase",
  //           }}
  //         >
  //           {posts.toString()}
  //         </h1>
  //       </div>
  //     </div>
  //     <div
  //       style={{
  //         paddingTop: 50,
  //         height: 60,
  //       }}
  //     >
  //       <div
  //         style={{
  //           justifyContent: "center",
  //           display: "flex",
  //         }}
  //       >
  //         <a
  //           style={{
  //             fontSize: 14,
  //             color: "#444e9f",
  //             textDecoration: "none",
  //           }}
  //           href="https://distrikt.io"
  //         >
  //           Join us
  //         </a>
  //       </div>
  //       <div
  //         style={{
  //           justifyContent: "center",
  //           display: "flex",
  //         }}
  //       >
  //         <p style={{ fontSize: 14 }}>
  //           © Copyright <script>document.write(new Date().getFullYear())</script>{" "}
  //           Open Internet Foundation
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default App;
