import {
  Actor,
  HttpAgent,
  makeNonceTransform,
  makeExpiryTransform,
} from '@dfinity/agent';
import idlFactory from './actorInterface';

const isProd = process.env.NODE_ENV === 'production';
const canisterId = String(
  isProd ?
    process.env.REACT_APP_CANISTER_ID :
    process.env.REACT_APP_LOCAL_CANISTER_ID
  );

let agentOptions: any = {};
agentOptions.host = process.env.REACT_APP_LOCAL_HOST;

const agent = new HttpAgent(agentOptions);

agent.addTransform(makeNonceTransform());
agent.addTransform(makeExpiryTransform( 2 * 60 * 1000));

const actor = Actor.createActor(
  idlFactory,
  {
    agent,
    canisterId,
  },
);

export default actor;